<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app temporary :width="220">
      <v-list nav dense>
        <v-list-item>
          <v-list-item-title>メニュー</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-title><v-icon>mdi-home</v-icon> Home </v-list-item-title>
          </v-list-item>
          <v-list-item to="/assign" v-if="group === 'admin' || group === 'user'">
            <v-list-item-title><v-icon>mdi-dip-switch</v-icon> タリー割当設定 </v-list-item-title>
          </v-list-item>
          <v-list-item to="/camerasetting" v-if="group === 'admin' || group === 'user'">
            <v-list-item-title><v-icon>mdi-video-wireless-outline</v-icon> 子機設定 </v-list-item-title>
          </v-list-item>
          <v-list-item to="/device" v-if="group === 'admin' || group === 'user'">
            <v-list-item-title><v-icon>mdi-view-list-outline</v-icon> デバイス一覧 </v-list-item-title>
          </v-list-item>
          <v-list-item to="/map">
            <v-list-item-title><v-icon>mdi-map-marker-outline</v-icon> マップ </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item class="d-flex justify-center">
              <amplify-sign-out />
            </v-list-item>
            <v-list-item-subtitle class=".text-caption">
              &copy;2021 RATOC Systems,Inc./ABC-TV
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-app-bar app dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        >
        <v-img
          alt="AirTally Logo"
          contain
          min-width="40"
          src="../assets/AirTally_logo_Rlarge_White.png"
          width="80"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      {{username}}
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";

window.LOG_LEVEL = "VERBOSE";

export default {
  name: "app",
  data() {
    return {
      username: "",
      group: "",
      drawer: null,
    };
  },
  async created() {
    this.user = await Auth.currentAuthenticatedUser();
    console.log(this.user);
    this.username = await this.user.username;
    this.group = await this.user.signInUserSession.accessToken.payload['cognito:groups'][0];
  },
};
</script>
